import { __ } from '~/locale';

export const AVAILABILITY_OPTIONS = {
  DEFAULT_ON: 'default_on',
  DEFAULT_OFF: 'default_off',
  NEVER_ON: 'never_on',
};

export const ERROR_MESSAGE = __(
  'An error occurred while retrieving your settings. Reload the page to try again.',
);
